import React from 'react';

export default function LoadingBox() {
  return (
    <div className="loading">
      Loading... {"\n"}
      <div style = {{fontSize: 100, margin: "auto", textAlign: "center"}} >
        <i className="fa fa-spinner fa-spin" ></i> 
      </div>
    </div>
  );
}