import axios from 'axios';
import React, { useState} from 'react';

export default function Accommodation(){
    const [countries, setCountries] = useState([]); //all countries from the database
    const [cities, setCities] = useState([]); // all cities from selected countries
    const [accommodationList,setAccommodationList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        const newValue = event.target.value;
        console.log('new value: ',newValue);
        console.log('selected value: ',selectedOption);
        setSelectedOption((prevValue) => ( prevValue === newValue ? null : newValue));
    };
    const [searchData, setSearchQuery ] = useState({     // user information 
        country: '',
        city: '',
        dateStart: '',
        dateEnd: '',
        numberOfGuest: "",
      });

      function updateData(type, newData){    // used for updating datas related to options in select
        setSearchQuery((searchData) =>{
          return {...searchData, [type]: newData}
        }); 
      };

      function handleSubmit(event) {
        event.preventDefault();
        // Perform any additional form submission logic here
        searchAccommodation(searchData.city,searchData.numberOfGuest);
      };

      const searchAccommodation = async (city,numberOfGuest) =>{// fetch search accommodation data
        try{
            const response = await axios.get(`/api/accommodation/search/city/${city}/numberOfGuest/${numberOfGuest}`);
            setAccommodationList(response.data);
        }catch (error){
            console.error('Error fetching search:', error);
        }
      }

      const fetchCity = async () =>{  // fetch country data
        try{
            const response = await axios.get(`/api/accommodation/cities/${searchData.country}`);
            setCities(response.data);
        }
        catch (error){
            console.log('Error fetching cities:', error);
        }

      };

      const fetchCountry = async () =>{  // fetch country list
        try{
            const response = await axios.get(`/api/accommodation/countries`);
            setCountries(response.data);
        }
        catch (error){
            console.log('Error fetching countries:', error);
        }

      };
         
    return(
        <div>
        <div className='row'>
            <form className='search-bar' >
            <select id="country" value={searchData.country}
            onChange={(e)=> updateData("country",e.target.value)}
            onClick={fetchCountry}
            >
                <option value="" disabled> Enter Country </option>
                {countries && countries.map((country)=>(
                    <option key={country} value={country}> {country}</option>
                ))}
            </select>
            <select id="destination" value={searchData.city}
            onChange={(e)=> updateData("city",e.target.value)}
            onClick={fetchCity}
            >
                <option value="" disabled> Enter City </option>
                {cities && cities.map((city)=>(
                    <option key={city} value={city}> {city}</option>
                ))}
            </select>
            <input type="date" id="dateStart"  onChange={(e)=>updateData("dateStart",e.target.value)}/>
            <input type="date" id="dateEnd"  onChange={(e)=>updateData("dateEnd",e.target.value)}/>
            <select id="numberOfGuest" value={searchData.numberOfGuest}
            onChange={(e)=> updateData("numberOfGuest",e.target.value)}
            >
                <option value="" disabled> Number of Guest </option>
                <option value={1}> 1 </option>
                <option value={2}> 2 </option>
                <option value={3}> 3 </option>
                <option value={4}> 4 </option>
                <option value={5}> 5 </option>
                <option value={6}> 6 </option>  
            </select>
            <button type='submit' onClick={handleSubmit}> search </button>
          </form>
          </div>

        <div >
            {accommodationList.length>0 ? 
            <div className='frame'>
                <div className='cards'>
                    {accommodationList && accommodationList.map((accommodation) => (
                    <div className='card'> 
                        <div className='img'> 
                            <img className='card-img' src={accommodation.image_1} alt="image_1"/> 
                            <button></button>
                        </div>
                        <div className='description'>
                            <p> ${accommodation.price_per_night} per night.</p>
                            <p> {accommodation.accommodation_type} . {accommodation.bedrooms} bedrooms . {accommodation.guests} guests </p>
                            <p> {accommodation.accommodation_name} </p>
                            <p> {accommodation.rating} {accommodation.rating_icon} ({accommodation.number_of_reviews} reviews)</p>
                            <p> {accommodation.city}, {accommodation.region} </p> 
                            
                        </div>
                     </div>
                    ))}
                </div>
                <div className='map'>
                    <label> Space for map. </label>  
                    <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="nearest"
                                        value="100m"
                                        checked={selectedOption === '100m'}
                                        onClick={handleOptionChange}
                                    />
                                    100m
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="nearest"
                                        value="200m"
                                        checked={selectedOption === '200m'}
                                        onClick={handleOptionChange}
                                    />
                                        200m
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="nearest"
                                        value="500m"
                                        checked={selectedOption === '500m'}
                                        onClick={handleOptionChange}
                                    />
                                        500m
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="nearest"
                                        value=">1km"
                                        checked={selectedOption === '>1km'}
                                        onClick={handleOptionChange}
                                    />
                                        &gt;1km
                                </label>
                            </div>  
                </div>
            </div>
            : <></>
            }
        </div>
    </div>
    );
}