import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import {
    adminDeleteReducer,
    adminDetailsReducer,
    adminListReducer,
    adminRegisterReducer,
    adminSigninReducer,
    adminUpdateProfileReducer,
    adminUpdateReducer,
  } from './reducers/adminReducers';

  const initialState = {
      adminSignin: {
      adminInfo: localStorage.getItem('adminInfo')
      ? JSON.parse(localStorage.getItem('adminInfo'))
      : null,
  },
  };
const reducer = combineReducers({
  adminSignin: adminSigninReducer,
  adminRegister: adminRegisterReducer,
  adminDetails: adminDetailsReducer,
  adminUpdateProfile: adminUpdateProfileReducer,
  adminUpdate: adminUpdateReducer,
  adminList: adminListReducer,
  adminDelete: adminDeleteReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;