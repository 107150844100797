import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MessageBox from '../components/MessageBox';

export default function EditAdmin(){
    const { id } = useParams();
    const [admin, setAdmin] =useState(
        {
            id: id,
            name: '',
            password: '',
            rePassword: '',
            role_admin: false,
            role_edit: false,
            role_view: false,
        }
    );
    const adminSignin = useSelector((state) => state.adminSignin);
    const { adminInfo } = adminSignin;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function updateData(type, newData){    // used for updating datas related to options in select
        setAdmin((admin) =>{
          return {...admin, [type]: newData}
        }); 
    }
    useEffect(()=> {
        if(!adminInfo){
            navigate("/signin");
        }
    },[adminInfo,navigate]);
    return(
        <div className="row">
            <form className="form col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-10 pt-10 "  >
                <div className="form-setup">
                    { adminInfo?.role_admin ?
                    <div className="form-input">
                        <p > Welcome to Admin panel</p>
                        <h1>Edit the admin </h1>
                    <div className="input-login">
                        <input type="integer" id="id" disabled required value={admin.id} ></input>
                        <label htmlFor="id"> Admin ID </label>
                    </div>
                    <div className="input-login">
                        <input type="name" id="name"  required onChange={(e) => updateData('name',e.target.value)} ></input>
                        <label htmlFor="name"> Name </label>
                    </div>
                    <div className="input-login">
                        <input type="password" id="password"  required onChange={(e) => updateData('password',e.target.value)} ></input>
                        <label htmlFor="password"> Password </label>
                    </div>
                    <div className="input-login">
                        <input type="password" id="rePassword"  required onChange={(e) => updateData('rePassword',e.target.value)} ></input>
                        <label htmlFor="rePassword"> Password Confirmation </label>
                    </div>
                    <div className="input-check">
                        <input type="checkbox" id="adminRole"   onChange={(e) => updateData('role_admin',e.target.value)} ></input>
                        <label htmlFor="adminRole"> Admin Role </label>
                    </div>
                    <div className="input-check">
                        <input type="checkbox" id="editRole"   onChange={(e) => updateData('role_edit',e.target.value)} ></input>
                        <label htmlFor="editRole"> Edit Role </label>
                    </div>
                    <div className="input-check">
                        <input type="checkbox" id="viewRole"   onChange={(e) => updateData('role_view',e.target.value)} ></input>
                        <label htmlFor="viewRole"> View Role </label>
                    </div>
                    <div className="button">
                        <button className="primary" type="submit" id="button"> Create </button>
                    </div>
                    </div>
                    : <MessageBox danger="danger">{{error: "Access denied, you are not authorized."}}</MessageBox> 
                }
                </div>
            </form>
        </div>
    );
}