import axios from 'axios';
import React, {useState} from 'react';

export default function Visa(){
    const [originCountry, setOriginCountry] = useState([]); //all origin country from the database
    const [destinationCountry, setDestinationCountry] = useState([]); //all destination from the database
    //const [ratings,setRatings] = useState([]);
   // const [destiantionList,setDestinationList] = useState([]);
    const [show,setShow] = useState(false); //show or hide the form
    const [errMsg,setErrMsg] = useState(null); //error message
    const [gcb,setGcb] = useState([]);
        
    const [searchData, setSearchQuery ] = useState({     // user information 
        origin_country: '',
        destination_country: '',
        duration: 0,
        employment_type: '',
        type_of_travel: '',
      });

      function updateData(type, newData){    // used for updating datas related to options in select
        setSearchQuery((searchData) =>{
          return {...searchData, [type]: newData}
        }); 
      };

      

      function handleSubmit(event) {
        event.preventDefault();
        // Perform any additional form submission logic here
        loadGCB();
       // console.log(gcb);
      };
      const loadGCB = async () =>{// fetch search destiantion data
        setErrMsg(null);
        try{
            const response = await axios.post(`/api/visa/gcb`, searchData);
            setGcb(response.data);
            setShow(true);
        }catch (error){
            setErrMsg('No match found or error occured');
            console.error('Error fetching gcb:', error);
            setShow(false);
        }
      }
console.log(searchData);
      const fetchOriginCountry = async () =>{  // fetch workstation type
        try{
            const response = await axios.get(`/api/visa/origin_country`);
            setOriginCountry(response.data);
        }
        catch (error){
            console.log('Error fetching origin country:', error);
        }

      };

      const fetchDestinationCountry = async (origin) =>{  // fetch workstation type
        try{
            const response = await axios.get(`/api/visa/destination_country/${origin}`);
            setDestinationCountry(response.data);
        }
        catch (error){
            console.log('Error fetching destination country:', error);
        }

      };

      //   console.log(searchData);
    return(
        <div>
        <div className='row'>
            <form className='form col-lg-10 col-md-10 col-sm-10 col-xs-12 mt-10 pt-10 '>
                            
                <form className='search-bar' >
            
                <select id="origin_country" value={searchData.origin_country}
                    onChange={(e)=> updateData("origin_country",e.target.value)}
                    onClick={fetchOriginCountry}
                >
                    <option value="" disabled> Origin country </option>
                        {originCountry && originCountry.map((country)=>(
                        <option key={country.id} value={country.country}> {country.country}</option>
                        ))}
                </select>
                <select id="destination_country" value={searchData.destination_country}
                    onChange={(e)=> updateData("destination_country",e.target.value)}
                    onClick={()=>fetchDestinationCountry(searchData.origin_country)}
                >
                    <option value="" disabled> Destination country </option>
                        {destinationCountry && destinationCountry.map((country)=>(
                        <option key={country.id} value={country.country}> {country.country}</option>
                        ))}
                </select>
                <select id="duration" value={searchData.duration}
                    onChange={(e)=> updateData("duration",e.target.value)}
                >
                    <option value={0} disabled> Duration </option>
                    <option value={30}> 30 days </option>
                    <option value={60}> 60 days </option>
                    <option value={90}> 90 days </option>
                    <option value={180}> 180 days </option>
                    <option value={181}> 181 days </option>
                </select>
                <select id="employment_type" value={searchData.employment_type}
                    onChange={(e)=> updateData("employment_type",e.target.value)}
                >
                    <option value="" disabled> Employment Type </option>
                    <option value="Employed"> Employed </option>
                    <option value="Self-Employed"> Self-Employed </option>
                </select>

                <select id="type_of_travel" value={searchData.type_of_travel}
                    onChange={(e)=> updateData("type_of_travel",e.target.value)}
                >
                    <option value="" disabled> Type of Travel </option>
                    <option value="Remote work / Workation"> Remote Work / Workation </option>
                    <option value="Holiday"> Holiday </option>
                    <option value="Business"> Business </option>
                    <option value="Employment"> Employment </option>
                </select>
            
                
                <button type='submit' onClick={handleSubmit}> search </button>
                </form>
            </form>
        </div>

      
       
        {show  ?
        <div className='row center'>
            <div className='form col-lg-10 col-md-10 col-sm-10 col-xs-12 mt-10 pt-10 '>

                <h1> {gcb.h1_title} </h1>
                <h2 className='m-10 p-10'> *** Step 0: Meta Data ***</h2>
                <div className='m-10 p-10' >
                    <h3> Title Visa </h3>
                    <p> {gcb.meta_title} </p>
                    <h4> Description Visa </h4>
                    <p> {gcb.meta_description} </p>
                    <h4> URL </h4>
                    <p> {gcb.visa_req_url} </p>

                </div>
                <div className='m-10 p-10' >
                    <h3> Title Insurance </h3>
                    <p> {gcb.meta_title_insurance} </p>
                    <h4> Description Insurance </h4>
                    <p> {gcb.meta_description_insurance} </p>
                    <h4> URL </h4>
                    <p> {gcb.insurance_url} </p>
                </div>
               
                <h1>{gcb.h1_title_visa}</h1>
                <p>{gcb.h1_text}</p>
                <h2 className='m-10 p-10'> *** Step 1: View visa requirements ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_visa} </h2>
                <div className=' form col-lg-4 col-md-6 col-sm-8 col-xs-10 mt-10 pt-10'>
                    <h3> {gcb.visa} </h3>
                    <p>{gcb.explanation_text}</p>
                </div>
                <p> {gcb.explanation_text_under_Visa_requirements_visual} </p>
                <div className='display-block'> 
                <a href={gcb.link_visa_button} target="_blank" rel="noopener noreferrer">
                <button className='primary btn-min-width'> {gcb.button_visa} </button> <br></br></a>
                <label> {gcb.text_below_to_action_button} </label>
                </div>
                <div className='display-block'>
                <a href={gcb.link_gov_web} target="_blank" rel="noopener noreferrer">
                    <button className='primary btn-min-width'> {gcb.text_button_link_to_gov_web} </button> <br></br>
                </a>
                    <label> {gcb.text_button_link_to_visa_help} </label>
                 </div>
                 <h3>{gcb.h3_source_visa}</h3>
                 <p>{gcb.content_source_visa}</p>
                <h2 className='m-10 p-10'> *** Step 2: Arrange your insurance ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_insurance}</h2>
                <h3>{gcb.h3_insurance}</h3>
                <div className=' form col-lg-4 col-md-4 col-sm-6 col-xs-8 mt-10 pt-10'>
                   <h3>{gcb.main_insurance}</h3> 
                   <h4> {gcb.recomanded_insurance} </h4> 
                   <p>{gcb.explanation_insurance} </p>
                </div>
                <p>{gcb.explanation_insurance_below}</p>
                <div className='display-block'> 
                <a href={gcb.link_comparision} target="_blank" rel="noopener noreferrer">
                <button className='primary btn-min-width'> {gcb.button_comparision} </button> <br></br>
                </a>
                <label> {gcb.text_below_comparision} </label>
                </div>
                <div className='display-block'>
                <a href={gcb.insurance_affiliate_links} target="_blank" rel="noopener noreferrer">
                <button className='primary btn-min-width'> {gcb.text_button_affiliate} </button> <br></br>
                </a>
                    
                    <label> {gcb.text_get_quote} </label>
                 </div>
                    <h3>{gcb.h3_source_insurance}</h3>
                    <p>{gcb.content_source_insurance}</p>
                <h2 className='m-10 p-10'> *** Step 3: Choose a destination ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_destination}</h2>
                <p> {gcb.destination_text}</p>
                <button className='primary btn-min-width'> {gcb.button_destination} </button>
                <p>{gcb.text_below_destination}</p>
                <h2 className='m-10 p-10'> *** Step 4: Find your perfect accommodation ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_accommodation}</h2>
                <button className='primary btn-min-width'> {gcb.button_accommodation} </button>
                <h2 className='m-10 p-10'> *** Step 5: Plan fun activities ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_activities}</h2>
                <div className='display-block'>
                <a href={gcb.activities_link} target="_blank" rel="noopener noreferrer">
                <button className='primary btn-min-width'> {gcb.button_activities} </button> <br></br>
                </a>
                </div>
                <h2 className='m-10 p-10'> *** Step 6: Do the FREE tax check ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_taxes}</h2>
                <p>{gcb.text_taxes}</p>
                <input type="text" placeholder={gcb.email_catcher} />
                <button className='primary btn-min-width'> {gcb.button_email_catcher} </button>
                <h2 className='m-10 p-10'> *** All done! Well done! ***</h2>
                <h2 className='m-10 p-10'> {gcb.h2_disclaimer}</h2>
                <div className='d-flex'>
                    <div className=' m-5 p-5 d-inline'><h3> A view </h3> <p>{gcb.h3_disclaimer_visa}</p></div>
                    <div className='m-5 p-5 d-inline'><h3> Insurance </h3><p>{gcb.h3_disclaimer_insurance}</p></div>
                    <div className='m-5 p-5 d-inline'><h3> General </h3>{gcb.h3_disclaimer_general}</div>
                </div>
            </div>
        </div> 
        : <div className='row center'> <h1 className='danger'> {errMsg} </h1> </div>}

            
        </div>
    );
}