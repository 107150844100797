import './App.css';
import React from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import HomeScreen from './screens/HomeScreen';
import AdminScreen from './screens/AdminScreen';
import Add from './screens/Add';
import ViewTable from './screens/ViewTable';
import Accommodation from './screens/Accommodation';
import Destination from './screens/Destination';
import Login from './screens/Login';
import CreateAdmin from './screens/CreateAdmin';
import EditAdmin from './screens/EditAdmin';
import { signout } from './actions/adminActions';
import ProfileScreen from './screens/ProfileScreen';
import Visa from './screens/VisaScreen';

function App() {
  const adminSignin = useSelector((state)=> state.adminSignin);
  const { adminInfo } = adminSignin;
  const dispatch = useDispatch();
  const signoutHandler = () => {
    dispatch(signout());
  }
  return (
    <BrowserRouter>
      <div className="grid-container ">
        <header>   
        <div className="row">
          <Link to="/"> Budget </Link>
          <Link to="/accommodation">Accommodation</Link>
          <Link to="/destination">Destination</Link>
          <Link to="/visa">Visa</Link>
          {adminInfo ? <> 
            <Link to="/admin"> Admin Panel</Link>
            <Admin adminInfo={adminInfo} signoutHandler={signoutHandler}/>
                       </> :
          <Link to="/signin"> Sign in</Link>}
        </div>
        </header>
      <main >
        <Routes>
          <Route path="/" element={<HomeScreen />} exact></Route>
          <Route path="/accommodation" element={<Accommodation />} ></Route>
          <Route path="/destination" element={<Destination />} ></Route>
          <Route path="/visa" element={<Visa />} ></Route>
          <Route path="/signin" element={<Login />} ></Route>
          <Route path="/admin" element={ <AdminScreen />} exact></Route>
          <Route path="/admin/add" element={<Add />} exact></Route>
          <Route path="/admin/viewtable" element={<ViewTable />} exact></Route>
          <Route path="/admin/createAdmin" element={<CreateAdmin />} ></Route>
          <Route path="/admin/edit/:id"  exact element={ <EditAdmin />} ></Route>
          <Route path="/admin/profile" element={ <ProfileScreen />}></Route>
        </Routes>
        </main>
        <footer className="row center mt-5  mb-5 p-10">
          <div>Created by <a href="https://hasansarwer.com" target='_blank' rel="noreferrer" >Hasan Sarwer </a>  </div>{' '}
        </footer>
      </div>
    </BrowserRouter>
  );
};


function Admin(props){
  const adminInfo = props.adminInfo;
  return(
      <div className="dropdown">
            <Link to="#">
               {adminInfo.name} <i className="fa fa-caret-down"></i>{' '}
            </Link>
              <ul className="dropdown-content">
                <li>
                  <Link to="/admin/profile">Adnin Profile</Link>
                </li>
                <li>
                  <Link to="signout" onClick={props.signoutHandler}>
                    Sign Out
                  </Link>
                </li>
              </ul>
          </div>
  )

};

export default App;