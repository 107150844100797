export const ADMIN_REGISTER_REQUEST = 'ADMIN_REGISTER_REQUEST';
export const ADMIN_REGISTER_SUCCESS = 'ADMIN_REGISTER_SUCCESS';
export const ADMIN_REGISTER_RESET = 'ADMIN_REGISTER_RESET';
export const ADMIN_REGISTER_FAIL = 'ADMIN_REGISTER_FAIL';

export const ADMIN_EXIST_REQUEST = 'ADMIN_EXIST_REQUEST';
export const ADMIN_EXIST = 'ADMIN_EXIST';
export const ADMIN_EXIST_FAIL = 'ADMIN_EXIST_FAIL';

export const ADMIN_SIGNIN_REQUEST = 'ADMIN_SIGNIN_REQUEST';
export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS';
export const ADMIN_SIGNIN_FAIL = 'ADMIN_SIGNIN_FAIL';

export const ADMIN_SIGNOUT = 'ADMIN_SIGNOUT';

export const ADMIN_DETAILS_REQUEST = 'ADMIN_DETAILS_REQUEST';
export const ADMIN_DETAILS_SUCCESS = 'ADMIN_DETAILS_SUCCESS';
export const ADMIN_DETAILS_FAIL = 'ADMIN_DETAILS_FAIL';
export const ADMIN_DETAILS_RESET = 'ADMIN_DETAILS_RESET';

export const ADMIN_LIST_REQUEST = 'ADMIN_LIST_REQUEST';
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';
export const ADMIN_LIST_FAIL = 'ADMIN_LIST_FAIL';

export const ADMIN_UPDATE_PROFILE_REQUEST = 'ADMIN_UPDATE_PROFILE_REQUEST';
export const ADMIN_UPDATE_PROFILE_SUCCESS = 'ADMIN_UPDATE_PROFILE_SUCCESS';
export const ADMIN_UPDATE_PROFILE_FAIL = 'ADMIN_UPDATE_PROFILE_FAIL';
export const ADMIN_UPDATE_PROFILE_RESET = 'ADMIN_UPDATE_PROFILE_RESET';

export const ADMIN_UPDATE_PASSWORD_REQUEST = 'ADMIN_UPDATE_PASSWORD_REQUEST';
export const ADMIN_UPDATE_PASSWORD_SUCCESS = 'ADMIN_UPDATE_PASSWORD_SUCCESS';
export const ADMIN_UPDATE_PASSWORD_FAIL = 'ADMIN_UPDATE_PASSWORD_FAIL';
export const ADMIN_UPDATE_PASSWORD_RESET = 'ADMIN_UPDATE_PASSWORD_RESET';

export const ADMIN_DELETE_REQUEST = 'ADMIN_DELETE_REQUEST';
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS';
export const ADMIN_DELETE_FAIL = 'ADMIN_DELETE_FAIL';
export const ADMIN_DELETE_RESET = 'ADMIN_DELETE_RESET';