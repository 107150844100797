import React, { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MessageBox from '../components/MessageBox';


export default function Add(){
  const adminSignin = useSelector((state) => state.adminSignin);
  const { adminInfo } = adminSignin;
    const [isFileUploaded,setIsFileUploaded] = useState(false);
    const [isDataUpdated,setIsDataUpdated] = useState(false);
    const [msg,setMsg] = useState('');

    const navigate = useNavigate();
    // Update budget tables
    function updateBudgetTable(){
        axios.post('/api/admin/update/budget_tool', {}, {
          headers: { Authorization: `Bearer ${adminInfo.token}` }
        })
        .then(() =>{
            setIsDataUpdated(true);
            console.log('Budget tables updated Successfully');
        })
        .catch((error) =>{
            console.error('Budget tables updated failed', error);
            setIsDataUpdated(false);
        })
    }
    // Update accommodation tables
    function updateAccommodationTable(){
      axios.get('/api/admin/update/accommodation_tool', {
        headers: { Authorization: `Bearer ${adminInfo.token}` }
      })
      .then(() =>{
          setIsDataUpdated(true);
          console.log('Accommodation tables updated Successfully');
      })
      .catch((error) =>{
          console.error('Accommodation tables updated failed', error);
          setIsDataUpdated(false);
      })
  }

  // Update destination tables
  function updateDestinationTable(){
    axios.get('/api/admin/update/destination_tool', {
      headers: { Authorization: `Bearer ${adminInfo.token}` }
    })
    .then(() =>{
        setIsDataUpdated(true);
        console.log('Destination tables updated Successfully');
    })
    .catch((error) =>{
        console.error('Destiantion tables updated failed', error);
        setIsDataUpdated(false);
    })
}

  // Update destination tables
  function updateVisaTable(){
    axios.get('/api/admin/update/visa_tool', {
      headers: { Authorization: `Bearer ${adminInfo.token}` }
    })
    .then(() =>{
        setIsDataUpdated(true);
        console.log('Visa tables updated Successfully');
    })
    .catch((error) =>{
        console.error('Visa tables updated failed', error);
        setIsDataUpdated(false);
    })
}
  // Function to handle the complete file upload
  const handleFileUpload = ({ file }) => {
    // Create a FormData object with the complete file
    const formData = new FormData();
    formData.append('file', file);

    // Send the complete file to the server
    axios.post('/api/admin/upload', formData,{ timeout: 600000, headers: { Authorization: `Bearer ${adminInfo.token}` }
  })
      .then(() => {
        console.log('Complete file uploaded successfully!');
        setMsg('Complete file uploaded successfully!');
        setIsFileUploaded(true);
      })
      .catch((error) => {
        console.error('Error uploading complete file:', error);
        setMsg('Error uploading complete file:'+ error);
        setIsFileUploaded(false);
      });
  };
  useEffect(()=> {
    if(!adminInfo){
        navigate("/signin");
    }
  },[adminInfo,navigate]);
    return(
      <>
      {adminInfo?.role_edit ?
      <div >
        <Dropzone
          onChangeStatus={handleFileUpload}
          multiple={false}
          maxFiles={1}
          inputContent="Drag and drop an Excel file or click to browse"
          accept=".xlsx, .xls"
        />
        <form className="form col-lg-8 col-md-10 col-sm-10 col-xs-10 mt-10 pt-10 ">
          {isFileUploaded ? <label>File uploaded successfully.</label> : <div> <label> </label> </div>}
          {isDataUpdated ? <label>Data updated successfully.</label> : <div> <label>{msg}</label> </div>}
          <div className='display-block'><button className='primary btn-min-width' onClick={updateBudgetTable}> Update Budget Tools </button></div>
          <div className='display-block'><button className='primary btn-min-width' onClick={updateAccommodationTable}> Update Accommodation Tools </button></div>
          <div className='display-block'><button className='primary btn-min-width' onClick={updateDestinationTable}> Update Destination Tools </button></div>
          <div className='display-block'><button className='primary btn-min-width' onClick={updateVisaTable}> Update Visa Tools </button></div>
        </form>        
      </div>
      : <MessageBox danger="danger">{{error: "Access denied, you are not authorized."}}</MessageBox>}
      </>
    )
}