import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../actions/adminActions";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

export default function Login(props){
    const [name,setName] = useState('');
    const [password,setPassword] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const redirect = location?.pathname
    ? location?.pathname?.split('=')[1]
    : '/';

    const adminSignin = useSelector((state) => state.adminSignin);
    const { adminInfo, loading, error } = adminSignin;

    const dispatch = useDispatch();

    const submitHandler = async (e) =>{
        e.preventDefault();
        dispatch(signin(name,password));
    };

    useEffect(() => {
        if (adminInfo) {
          navigate("/admin");
        }
    }, [ navigate, redirect, adminInfo]);

    return (
        <div className="row">
            <form className="form col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-10 pt-10 " onSubmit={submitHandler} >
                <div className="form-setup">
                    <div className="form-input">
                    <div>
                    <h1>Sign In</h1>
                    <p > Sign in to continue with Admin panel</p>
                    {loading? <LoadingBox /> : <></>}
                        {error? <MessageBox danger="danger">{error} </MessageBox> : <></>}
                    <div className="input-login">
                        <input type="name" id="name" autoComplete required onChange={(e) => setName(e.target.value)} ></input>
                        <label htmlFor="name"> Name </label>
                    </div>
                    <div className="input-login">
                        <input type="password" id="password" autoComplete required onChange={(e) => setPassword(e.target.value)} ></input>
                        <label htmlFor="password"> Password </label>
                    </div>
                    <div className="button">
                        <button className="primary" type="submit" id="button"> Sign In </button>
                    </div>
                </div>
                    </div>
                </div>
            </form>
        </div>
    );
}