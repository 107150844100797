import Axios from 'axios';
import {
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_REGISTER_FAIL,
  ADMIN_REGISTER_REQUEST,
  ADMIN_REGISTER_SUCCESS,
  ADMIN_SIGNIN_FAIL,
  ADMIN_SIGNIN_REQUEST,
  ADMIN_SIGNIN_SUCCESS,
  ADMIN_SIGNOUT,
  ADMIN_UPDATE_PROFILE_FAIL,
  ADMIN_UPDATE_PROFILE_REQUEST,
  ADMIN_UPDATE_PROFILE_SUCCESS,
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DELETE_FAIL,
  ADMIN_UPDATE_PASSWORD_SUCCESS,
  ADMIN_UPDATE_PASSWORD_REQUEST,
  ADMIN_UPDATE_PASSWORD_FAIL
} from '../constants/adminConstants';


export const register = (dataIn) => async (dispatch, getState) => {
    dispatch({ type: ADMIN_REGISTER_REQUEST, payload: dataIn });
    const {
      adminSignin: { adminInfo },
    } = getState();
    try {
      const { data } = await Axios.post('/api/admin/register', dataIn, {
        headers: { Authorization: `Bearer ${adminInfo.token}` },
      });
      dispatch({ type: ADMIN_REGISTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ADMIN_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const signin = (name, password) => async (dispatch) => {
  dispatch({ type: ADMIN_SIGNIN_REQUEST, payload: { name, password } });
  try {
    const { data } = await Axios.post('/api/admin/signin', { name, password });
    dispatch({ type: ADMIN_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('adminInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: ADMIN_SIGNIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const signout = () => (dispatch) => {
  localStorage.removeItem('adminInfo');
  dispatch({ type: ADMIN_SIGNOUT });
  document.location.href = '/signin';
};

export const detailsADMIN = (adminId) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_DETAILS_REQUEST, payload: adminId });
  const {
    adminSignin: { adminInfo },
  } = getState();
  try {
    const { data } = await Axios.get(`/api/admin/${adminId}`, {
      headers: { Authorization: `Bearer ${adminInfo.token}` },
    });
    dispatch({ type: ADMIN_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_DETAILS_FAIL, payload: message });
  }
};
export const updateAdminProfile = (admin) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_UPDATE_PROFILE_REQUEST, payload: admin });
  const {
    adminSignin: { adminInfo },
  } = getState();
  try {
    const { data } = await Axios.put(`/api/admin/profile`, admin, {
      headers: { Authorization: `Bearer ${adminInfo.token}` },
    });
    dispatch({ type: ADMIN_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch({ type: ADMIN_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem('adminInfo', JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_UPDATE_PROFILE_FAIL, payload: message });
  }
};
export const updatePassword = (passwords) => async (dispatch, getState) => {
  const {
    adminSignin: { adminInfo },
  } = getState();
  dispatch({ type: ADMIN_UPDATE_PASSWORD_REQUEST, payload: {adminInfo,passwords} });
  try {
    const { data } = await Axios.put(`/api/admin/updatePassword/${adminInfo._id}`, adminInfo, {
      headers: { Authorization: `Bearer ${adminInfo.token}` },
    });
    dispatch({ type: ADMIN_UPDATE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_UPDATE_PASSWORD_FAIL, payload: message });
  }
};

export const listAdmins = () => async (dispatch, getState) => {
  dispatch({ type: ADMIN_LIST_REQUEST });
  try {
    const {
      adminSignin: { adminInfo },
    } = getState();
    const { data } = await Axios.get('/api/admins', {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    });
    dispatch({ type: ADMIN_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_LIST_FAIL, payload: message });
  }
};
export const deleteAdmin = (adminId) => async (dispatch, getState) => {
  dispatch({ type: ADMIN_DELETE_REQUEST, payload: adminId });
  const {
    adminSignin: { adminInfo },
  } = getState();
  try {
    const { data } = await Axios.delete(`/api/admin/${adminId}`, {
      headers: { Authorization: `Bearer ${adminInfo.token}` },
    });
    dispatch({ type: ADMIN_DELETE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ADMIN_DELETE_FAIL, payload: message });
  }
};