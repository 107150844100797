import React, {  useEffect, useState} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MessageBox from '../components/MessageBox';
import { deleteAdmin } from '../actions/adminActions';
import { ADMIN_DELETE_RESET } from '../constants/adminConstants';
import LoadingBox from '../components/LoadingBox';


export default function ViewTable(){
    const [tableList, setTableList] = useState([]);
    const [table, setTable] = useState('');
    const [tableData, setTableData ] = useState([]);
    const [isTableViewed, setIsTableViewed] =useState(false);

    const adminSignin = useSelector((state) => state.adminSignin);
    const { adminInfo } = adminSignin;

    const adminDelete = useSelector((state) => state.adminDelete);
    const {
    loading: loadingDelete,
    error: errorDelete,
    message: successMessage,
  } = adminDelete;

    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    function formatString(input) {
      // Replace underscores with spaces
      let formattedString = input.replace(/_/g, ' ');
    
      // Capitalize the first letter of each word
      formattedString = formattedString.replace(/(?:^|\s)\S/g, function (char) {
        return char.toUpperCase();
      });
    
      return formattedString;
    }
      
      const getTable = async() => {
        try{
          const response = await axios.post(`/api/admin/tableData/${table}`,{}, {
            headers: { Authorization: `Bearer ${adminInfo.token}` },
          });
          setTableData(response.data);
        }
        catch(error){
          console.error('Error getting row:', error.response.data.error);
        }
      }
      

      function reset (){
          setTable('');
          setTableData([]);
      //    setHeads('');
          setIsTableViewed(false);
      }

      function handleSubmit(event) {
        event.preventDefault();
        // insert Table
      //  getHeads();
        getTable();
        setIsTableViewed(true);
      }

      function deleteHandler(e,id){
        e.preventDefault();
        console.log(e,id);
        dispatch(deleteAdmin(id));
      }

      useEffect(()=>{
        const getTableList = async() => {
          try{
            const response = await axios.post(`/api/admin/tableList`, {}, {
              headers: { Authorization: `Bearer ${adminInfo.token}` },
            });
            setTableList(response.data);
          }
          catch(error){
            console.error('Error getting table list:', error.response.data.error);
          }
        }
        getTableList();
      },[adminInfo]);
      

      useEffect(()=> {
        if(!adminInfo){
            navigate("/signin");
        }
      },[adminInfo,navigate]);
      useEffect(() => {
        const getTable = async() => {
          try{
            const response = await axios.post(`/api/admin/tableData/${table}`,{}, {
              headers: { Authorization: `Bearer ${adminInfo.token}` },
            });
            setTableData(response.data);
          }
          catch(error){
            console.error('Error getting row:', error.response.data.error);
          }
        }
        if (successMessage) {
          getTable();
          dispatch({type: ADMIN_DELETE_RESET})
        }
      }, [table, successMessage, dispatch, adminInfo]);

    return(
         <div className='row'>
          {adminInfo?.role_view ? <>
          { !isTableViewed ? <form className='form col-lg-6 col-md-8 col-sm-12 col-xs-12 mt-10 pt-10 ' onSubmit={handleSubmit}>
          <div className='input-field'>
          {loadingDelete? <LoadingBox /> : <></>}
          
            <label htmlFor="countrySelect" >
                Select a table:
                </label>
                <select id="countrySelect" value={table} onChange={(e) => {setTable( e.target.value); setTableData([])}} >
                    <option value="" disabled> Select a table </option>
                    {tableList && tableList.map((tableName,index)=>(
                      <option key={index} value={tableName}>{formatString(tableName)}</option>
                    ))}
                </select>
            </div>
            <div style={{margin: '10px', padding: '1rem'}}>
            <button className="primary" style={{margin: '10px'}} type="submit" onClick={handleSubmit}>
            View Table
          </button>
            <button type='button' style={{margin: '10px'}} onClick={reset}> Reset</button>
            </div>
          </form> :

          <div className="table-container">
            <h1> Table: {formatString(table)}</h1>
            <button type='button' style={{margin: '10px'}} onClick={reset}> Reset</button>
            {loadingDelete? <LoadingBox /> : <></>}
            { errorDelete ? <MessageBox danger="danger">{errorDelete} </MessageBox> : <></>}
            {successMessage ? <MessageBox >{successMessage} </MessageBox> : <></>}
            <table className=" table">
              <thead>
              <tr>
                {tableData && tableData[0] && Object.keys(tableData[0]).map((head,index) =>(
                  <td key={index}>{formatString(head)}</td>
                ))}
                {adminInfo?.role_admin && table && table==='admins' && <td>  Create / Edit </td> }
                {adminInfo?.role_admin && table && table==='admins' && <td> Delete </td> }
                </tr>
                </thead>
                <tbody>
                {tableData && tableData.map((row,rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(row).map((cell,index) =>(
                      <td key={index}>{cell}</td>
                    ))}
                  {adminInfo?.role_admin && table && table==='admins' &&  <td><button type='button' style={{margin: '10px'}} onClick={()=> navigate(`/admin/edit/${row.id}`)}> Edit Admin </button></td>}
                  {adminInfo?.role_admin && table && table==='admins' &&  <td><button type='button' style={{margin: '10px'}} onClick={(e)=> deleteHandler(e,row.id)}>  Delete </button></td>}
                  </tr>
                ))}
                {table && table==='admins' && 
                <tr>
                  <td>  </td>
                  <td>  </td>
                  <td> </td>
                  <td> </td>
                  <td>  </td>
                  <td> </td>
                  <td> <button type='button' style={{margin: '10px'}} onClick={()=> navigate("/admin/createAdmin")}> Create Admin </button> </td>
                </tr>}
              </tbody>
            </table>
          </div>}
          </> : <MessageBox danger="danger">{{error: "Access denied, you are not authorized."}}</MessageBox>}
          </div>
        
    );
}
