import {
    ADMIN_DELETE_FAIL,
    ADMIN_DELETE_REQUEST,
    ADMIN_DELETE_RESET,
    ADMIN_DELETE_SUCCESS,
    ADMIN_DETAILS_FAIL,
    ADMIN_DETAILS_REQUEST,
    ADMIN_DETAILS_RESET,
    ADMIN_DETAILS_SUCCESS,
    ADMIN_LIST_FAIL,
    ADMIN_LIST_REQUEST,
    ADMIN_LIST_SUCCESS,
    ADMIN_REGISTER_FAIL,
    ADMIN_REGISTER_REQUEST,
    ADMIN_REGISTER_SUCCESS,
    ADMIN_SIGNIN_FAIL,
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNOUT,
    ADMIN_UPDATE_PASSWORD_FAIL,
    ADMIN_UPDATE_PROFILE_FAIL,
    ADMIN_UPDATE_PROFILE_REQUEST,
    ADMIN_UPDATE_PROFILE_RESET,
    ADMIN_UPDATE_PROFILE_SUCCESS,
    ADMIN_UPDATE_PASSWORD_REQUEST,
    ADMIN_UPDATE_PASSWORD_RESET,
    ADMIN_UPDATE_PASSWORD_SUCCESS,
    ADMIN_REGISTER_RESET
    } from '../constants/adminConstants';
    
    export const adminRegisterReducer = (state = {}, action) => {
      switch (action.type) {
        case ADMIN_REGISTER_REQUEST:
          return { loading: true };
        case ADMIN_REGISTER_SUCCESS:
          return { loading: false, adminReg: action.payload };
        case ADMIN_REGISTER_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_REGISTER_RESET:
          return {};
        default:
          return state;
      }
    };
  
    export const adminSigninReducer = (state = {}, action) => {
      switch (action.type) {
        case ADMIN_SIGNIN_REQUEST:
          return { loading: true };
        case ADMIN_SIGNIN_SUCCESS:
          console.log(action.payload);
          return { loading: false, adminInfo: action.payload };
        case ADMIN_SIGNIN_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_SIGNOUT:
          return {};
        default:
          return state;
      }
    };
    
    export const adminDetailsReducer = (state = { loading: true }, action) => {
      switch (action.type) {
        case ADMIN_DETAILS_REQUEST:
          return { loading: true };
        case ADMIN_DETAILS_SUCCESS:
          return { loading: false, admin: action.payload };
        case ADMIN_DETAILS_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_DETAILS_RESET:
          return { loading: true };
        default:
          return state;
      }
    };
    export const adminUpdateProfileReducer = (state = {}, action) => {
      switch (action.type) {
        case ADMIN_UPDATE_PROFILE_REQUEST:
          return { loading: true };
        case ADMIN_UPDATE_PROFILE_SUCCESS:
          return { loading: false, success: true };
        case ADMIN_UPDATE_PROFILE_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_UPDATE_PROFILE_RESET:
          return {};
        default:
          return state;
      }
    };
    export const adminUpdateReducer = (state = {}, action) => {
      switch (action.type) {
        case ADMIN_UPDATE_PASSWORD_REQUEST:
          return { loading: true };
        case ADMIN_UPDATE_PASSWORD_SUCCESS:
          return { loading: false, success: true };
        case ADMIN_UPDATE_PASSWORD_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_UPDATE_PASSWORD_RESET:
          return {};
        default:
          return state;
      }
    };
    export const adminListReducer = (state = { loading: true }, action) => {
      switch (action.type) {
        case ADMIN_LIST_REQUEST:
          return { loading: true };
        case ADMIN_LIST_SUCCESS:
          return { loading: false, admins: action.payload };
        case ADMIN_LIST_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    };
    export const adminDeleteReducer = (state = {}, action) => {
      switch (action.type) {
        case ADMIN_DELETE_REQUEST:
          return { loading: true };
        case ADMIN_DELETE_SUCCESS:
          return { loading: false, success: true , message: action.payload.message };
        case ADMIN_DELETE_FAIL:
          return { loading: false, error: action.payload };
        case ADMIN_DELETE_RESET:
          return {};
        default:
          return state;
      }
    };
