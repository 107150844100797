import axios from 'axios';
import React, { useEffect, useState} from 'react';

export default function Destination(){
    const [workStationTypes, setWorkStationTypes] = useState([]); //all workStationTypes from the database
    const [continents, setContinents] = useState([]); // all continents
    //const [ratings,setRatings] = useState([]);
    const [destiantionList,setDestinationList] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    
    const [searchData, setSearchQuery ] = useState({     // user information 
        workStationType: '',
        continent: '',
        rating: '',
        base: 'country'
      });

     /* function updateWorkStationTypes(type, newData){    // used for updating datas related to options in select
        setWorkStationTypes((workStationTypes) =>{
          return {...workStationTypes, [type]: newData}
        }); 
      };

      function updateNameValue(id){
        updateWorkStationTypes('value', workStationTypes[id-1].value);
        updateWorkStationTypes('name', workStationTypes[id-1].name);
      }*/

      function updateData(type, newData){    // used for updating datas related to options in select
        setSearchQuery((searchData) =>{
          return {...searchData, [type]: newData}
        }); 
      };

      function handleSubmit(event) {
        event.preventDefault();
        // Perform any additional form submission logic here
        searchDestination(searchData);
      };

      const searchDestination = async (searchData) =>{// fetch search destiantion data
        try{
            const response = await axios.post(`/api/destination/search`, {searchData});
            setDestinationList(response.data);
        }catch (error){
            console.error('Error fetching search:', error);
        }
      }

      const fetchWorkstationType = async () =>{  // fetch workstation type
        try{
            const response = await axios.get(`/api/destination/workstation_type`);
            setWorkStationTypes(response.data);
        }
        catch (error){
            console.log('Error fetching workStationTypes:', error);
        }

      };

        // Function to toggle the state
  const handleToggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

      const fetchContinent = async () =>{  // fetch continents type
        try{
            const response = await axios.get(`/api/destination/continents`);
            setContinents(response.data);
        }
        catch (error){
            console.log('Error fetching continents:', error);
        }

      };

      useEffect(() => {
        if(isToggled){
            updateData("base", "city");
        }
        else{
            updateData("base", "country");
        }
        }, [isToggled]);

         console.log(searchData);
    return(
        <div>
        <div className='row'>
        <div className='input-field'>
                <label className='view'> Show calculatuon for: </label>
                <button type='button' onClick={handleToggle}>
                    {!isToggled ? 'City' : 'country'}
                </button>
                <label className='view'> Showing calculatuon for: {isToggled ? 'City' : 'country'} </label>
            </div>
            
            <form className='search-bar' >
            
            <select id="workstation" value={searchData.workStationType}
            onChange={(e)=> updateData("workStationType",e.target.value)}
            onClick={fetchWorkstationType}
            >
                <option value="" disabled> Type of workstation </option>
                {workStationTypes && workStationTypes.map((workStationType)=>(
                    <option key={workStationType.id} value={workStationType.value}> {workStationType.name}</option>
                ))}
            </select>
            <select id="continent" value={searchData.continent}
            onChange={(e)=> updateData("continent",e.target.value)}
            onClick={fetchContinent}
            >
                <option value="" disabled> Enter Continent </option>
                {continents && continents.map((continent)=>(
                    <option key={continent} value={continent}> {continent}</option>
                ))}
            </select>
            
            <select id="rating" value={searchData.rating}
            onChange={(e)=> updateData("rating",e.target.value)}
            >
                <option value="" disabled> Work staion rating </option>
                <option value={1}> 1 </option>
                <option value={2}> 2 </option>
                <option value={3}> 3 </option>
                <option value={4}> 4 </option>
                <option value={5}> 5 </option>
            </select>
            <button type='submit' onClick={handleSubmit}> search </button>
          </form>
          </div>

        <div >
            {destiantionList.length>0 ? 
            <div className='frame'>
                <div className='cards'>
                    {destiantionList && destiantionList.map((destination) => (
                    <div className='card'> 
                        <div className='img'> 
                            <img className='card-img' src={destination.image_1} alt="image_1"/> 
                            <button></button>
                        </div>
                        <div className='description'>
                            <p> ${destination.price_per_night} per night.</p>
                            <p> {destination.destination_type} . {destination.bedrooms} bedrooms . {destination.guests} guests </p>
                            <p> {destination.destination_name} </p>
                            <p> {destination.rating} {destination.rating_icon} ({destination.number_of_reviews} reviews)</p>
                            <p> {destination.city}, {destination.region} </p> 
                        </div>
                     </div>
                    ))}
                </div>
                <div className='map'>
                    <label> Space for map. </label>    
                </div>
            </div>
            : <></>
            }
        </div>
    </div>
    );
}