import axios from 'axios';
import React, { useEffect, useState} from 'react';


export default function HomeScreen(){
    const [baseCost, setBaseCost] = useState({});
    const [baseCostCountry, setBaseCostCountry] = useState({});
    const [baseCostCity, setBaseCostCity] = useState({});
    const [options, setOptions] = useState({
        countries: [],
        cities: [],
        familySize: [],
        travelStyle: [],
        accommodation: [],
        duration: []
    });   // for generating dynamic options in selecet
    const [countryData,setCountryData] = useState(''); // all country information like links, indices, continent 
    const [cityData,setCityData] = useState(''); // all city information  indices, country, continent 
    const [baseData, setBaseData] = useState('');     // base data same as country, where country is Neteherlands
    const [baseDataCountry, setBaseDataCountry] = useState('');     // base data same as country, where country is Neteherlands
    const [baseDataCity, setBaseDataCity] = useState(''); 
    const [totalCost, setTotalCost] = useState(0);
    const [formSubmitted,setFormSumitted] = useState(true);
    const [isDisable, setIsDisable] = useState(true);
    const [isToggled, setIsToggled] = useState(false);
    const [toggleData, setToggleData] = useState(''); // Switiching Data between city and country
    const [indeces, setIndeces] = useState({      // indices for how many times than standard size, number or type
        familySize: 1,
        familySizeAccommodation: 1,
        familySizeHostel: 1,
        restaurantValue: 1,
        accommodationValue: 1,
        carRentalValue: 1,
        base: 0,
        discount: 0,
        afterDiscount: 1,
        eatingOutDuration: 0,
        groceryDiscount: 0
    });
    const [costs, setCosts] = useState({            // cost record of all our interest
        flightCost: 0,
        transport: 0,
        accommodation: 0,
        activities: 0,
        grocery: 0,
        eatingOut: 0
    });
    const [userData, setUserData ] = useState({     // user information 
        country: '',
        city: '',
        familySize: '',
        travelStyle: '',
        accommodation: '',
        eatingOut: '',
        activities: '',
        transport: '',
        duration: '',
      });
      // State to manage the toggle
  

  // Function to toggle the state
  const handleToggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };
      function reset(){                 // reset when user needs
        updateData("country", '');
        updateData("city", '');
        updateData("familySize", '');
        updateData("travelStyle", '');
        updateData("accommodation", '');
        updateData("eatingOut", '');
        updateData("activities", '');
        updateData("transport", '');
        updateData("duration", '');
        setTotalCost(0);
      };

      function updateOptions(type, newData){               // update user specified data
        setOptions((options) =>{
          return {...options, [type]: newData}
        }); 
      };

      function updateCost(type, newData){              // updates costs dynamically 
        setCosts((costs) =>{
          return {...costs, [type]: newData}
        }); 
      };

      function updateData(type, newData){    // used for updating datas related to options in select
        setUserData((userData) =>{
          return {...userData, [type]: newData}
        }); 
      };

      function updateIndeces(type, newData){    // used for updating datas related to options in select
        setIndeces((indeces) =>{
          return {...indeces, [type]: newData}
        }); 
      };

      function handleSubmit(event) {
        event.preventDefault();
        // Perform any additional form submission logic here
        setFormSumitted(!formSubmitted);
      };

      const fetchCountryData = async (country) =>{  // fetch country data
        try{
            const response = await axios.get(`/api/budget/countryData/${country}`);
            setCountryData(response.data[0]);
        }
        catch (error){
            console.log('Error fetching data:', error);
        }

      };

      const fetchCityData = async (city) =>{  // fetch country data
        try{
            const response = await axios.get(`/api/budget/cityData/${city}`);
            setCityData(response.data[0]);
        }
        catch (error){
            console.log('Error fetching data:', error);
        }

      };

      const fetchBaseData = async (country) =>{ // fetch base data Neterlands
        try{
            const response = await axios.get(`/api/budget/countryData/${country}`);
            setBaseDataCountry(response.data[0]);
        }
        catch (error){
            console.log('Error fetching data:', error);
        }

      };

      const fetchBaseDataCity = async (city) =>{ // fetch base data Amsterdam
        try{
            const response = await axios.get(`/api/budget/cityData/${city}`);
            setBaseDataCity(response.data[0]);
        }
        catch (error){
            console.log('Error fetching data:', error);
        }

      };

      const fetchOptions = async (queryData) => {    //fetch for options
        try{
            const response = await axios.get(`/api/budget/${queryData}`);
            updateOptions(queryData,response.data);
        }
        catch(error) {
          console.log('Error fetching countries data:', error);
        };
        };

        const fetchCities = async (country) => {    //fetch for options
            try{
                const response = await axios.get(`/api/budget/cities/${country}`);
                updateOptions('cities',response.data);
            }
            catch(error) {
              console.log('Error fetching cities data:', error);
            };
        };

    //choose set base data country or city base data
    useEffect(()=>{
        if(isToggled){
            setBaseData(baseDataCity);
            setToggleData(cityData);
            setBaseCost(baseCostCity);
        }else{
            setBaseData(baseDataCountry);
            setToggleData(countryData);
            setBaseCost(baseCostCountry);
        }
    },[isToggled,baseDataCity,baseDataCountry,cityData,countryData,baseCostCity,baseCostCountry]);

    useEffect(()=>{
        if(userData.country){
            fetchCountryData(userData.country);
        }
    },[userData.country]);

    useEffect(()=>{
        if(userData.city){
            fetchCityData(userData.city);
        }
    },[userData.city]);

    useEffect(()=>{
        const fetchFamilySize = async (family) => {    //fetch for options
            try{
                const response = await axios.get(`/api/budget/family/${family}`);
                const result = response.data[0];
                updateIndeces('familySize', result.family_size_index);
                updateIndeces('familySizeAccommodation', result.accommodation_size);
                updateIndeces('familySizeHostel', result.hostel_size);
            }
            catch(error) {
                console.log('Error fetching data:', error);
            }
            };
        if(userData.familySize){
            const familySize = userData.familySize;
            fetchFamilySize(familySize);
        }
    },[userData.familySize]);

    useEffect(()=>{
        const fetchTravelStyle = async (travelStyle) => {    //fetch for options
            try{
                const response = await axios.get(`/api/budget/travel/${travelStyle}`);
                const result = response.data[0];
                updateIndeces('restaurantValue', result.restaurant);
                updateIndeces('accommodationValue', result.accommodation);
                updateIndeces('carRentalValue', result.car);
                updateIndeces('base',result.base);
            }
            catch(error) {
                console.log('Error fetching data:', error);
            }
            };

            if(userData.travelStyle){
                const travelStyle = userData.travelStyle;
                fetchTravelStyle(travelStyle);
            }

    },[userData.travelStyle]);

    useEffect(()=>{
        const fetchAccommodationIndex = async (duration) =>{    //fetch for options
            try{
                const response = await axios.get(`/api/budget/duration/${duration}`);
                const result = response.data[0];
                updateIndeces('discount',result.discount_rate);
                updateIndeces('afterDiscount',1-result.discount_rate);
            }
            catch(error) {
                console.log('Error fetching data:', error);
            }
            };
        if(userData.duration){
            const duration = userData.duration;
            fetchAccommodationIndex(duration);
        }
    },[userData.duration]);

    useEffect(() =>{            // when country is changed  fetch all country data
            const fetchEatingOut = async (eatingOut) =>{
                try{
                    const response = await axios.get(`/api/budget/eatingOut/${eatingOut}`);
                    const result = response.data[0];
                    updateIndeces('eatingOutDuration',result.duration);
                    updateIndeces('groceryDiscount',result.calculation_discount_groceries);
                }catch(error){
                    console.log('Error fetching data: ',error);
                }
            }
        if(userData.eatingOut){
            const eating_out = userData.eatingOut;
            fetchEatingOut(eating_out);
        }
    },[userData.eatingOut]);

    useEffect(()=>{
        setIsDisable(!(userData.accommodation !=="" && userData.country !=="" && userData.familySize !=="" && userData.travelStyle !=="" && userData.activities !=="" && userData.eatingOut !==""&& userData.duration !==0 && userData.transport !==""));
    },[userData]);

    useEffect(()=>{
        setTotalCost(costs.accommodation + costs.activities + costs.flightCost + costs.grocery + costs.transport + costs.eatingOut);
    },[costs])

    useEffect(() =>{
        const fetchBaseCost = async () => {
            try{
                const response = await axios.get('/api/budget/baseCost/country');
               // console.log('base cost inside: ',response.data);
                setBaseCostCountry(response.data.base_cost_country);
            }catch(error){
                console.log('Error fetching base cost country:', error);
            }
            try{
                const response = await axios.get('/api/budget/baseCost/city');
                //console.log('base cost city inside: ',response.data);
                setBaseCostCity(response.data.base_cost_city);
            }catch(error){
                console.log('Error fetching base cost:', error);
            }
        }
        fetchBaseCost();
    },[]);

    useEffect(() => {   // calculate costs dynamically
        if(toggleData){
            let flightCost;
            if(toggleData.continent===baseData.continent){
            flightCost = baseCost.flight_continent;
            flightCost = flightCost*toggleData.cost_of_living_index/baseData.cost_of_living_index;
            flightCost = Math.round(flightCost*indeces.familySize);
            }
            else{
                flightCost = baseCost.flight_intercontinent;
                flightCost = Math.round(flightCost*indeces.familySize);
            }
            updateCost('flightCost',flightCost);
        }
        if(userData.accommodation){
            let accommodation =0;
            if(userData.accommodation==='hotel'){
                accommodation = baseCost.hotel*toggleData.rent_index/baseData.rent_index;
                accommodation = accommodation*indeces.familySizeAccommodation;
            }
            else if(userData.accommodation==='hostel'){
                accommodation = baseCost.hostel*toggleData.rent_index/baseData.rent_index;
                accommodation = accommodation * indeces.familySizeHostel;
            }
            else {
                accommodation = baseCost.apartment*toggleData.rent_index/baseData.rent_index;
                accommodation = accommodation*indeces.familySizeAccommodation;
            }
            accommodation = (userData.duration*accommodation*indeces.accommodationValue);
            accommodation = Math.round(accommodation*indeces.afterDiscount);
            updateCost('accommodation',accommodation);
        }
        if(userData.transport){
            let transport =0;
            switch (userData.transport){
                case 'car':
                    transport = baseCost.rent_a_car*indeces.carRentalValue*toggleData.cost_of_living_index/baseData.cost_of_living_index;
                    break;
                case 'taxi':
                    transport = baseCost.taxi*toggleData.cost_of_living_index/baseData.cost_of_living_index;
                    break;
                default :
                 transport = baseCost.public_transport*indeces.familySize*toggleData.cost_of_living_index/baseData.cost_of_living_index;
            }
            transport = Math.round(transport*userData.duration);
            updateCost('transport',transport);
        }
        if(userData.activities){
          let activities =0;
          switch(userData.activities){
            case 'free':
                activities =baseCost.other_free;
                break;
            case 'paid':
                activities = baseCost.other_paid;
                break;
            default :
                activities = baseCost.both_paid_free;
          }
          activities = indeces.familySize*activities;
          activities = activities*toggleData.cost_of_living_index/baseData.cost_of_living_index;
          activities = Math.round(activities*userData.duration);
          updateCost('activities',activities);
        }
        if(userData.eatingOut){
            let eatingOut =0;
            eatingOut = baseCost.breakfast +baseCost.dinner;
            eatingOut = eatingOut*toggleData.restaurant_price_index/baseData.restaurant_price_index;
            let grocery =0;
            grocery = baseCost.groceries*toggleData.groceries_index/baseData.groceries_index;
            grocery = grocery*indeces.familySize;  
            grocery = grocery*indeces.restaurantValue;
            eatingOut = eatingOut*indeces.eatingOutDuration;
            grocery = grocery*indeces.groceryDiscount;         
            eatingOut = eatingOut*indeces.familySize*indeces.restaurantValue;
            eatingOut = Math.round(eatingOut*userData.duration);
            grocery = Math.round(grocery*userData.duration);
            updateCost('eatingOut',eatingOut);
            updateCost('grocery',grocery);
        }
          
            
    },[toggleData,indeces,userData,baseData,baseCost]);
    
    useEffect(()=>{             // load base data when the page is loaded
        if(!baseData){
        fetchBaseData('Netherlands');
        fetchBaseDataCity('Amsterdam');
        }
    },[baseData]);
      
    return(
        <div className='row'>
          {formSubmitted  ?  <form className="form col-lg-8 col-md-10 col-sm-10 col-xs-10 mt-10 pt-10 " onSubmit={handleSubmit} > 
          
            <div >
            <div className='input-field'>
                <label className='view'> Show calculatuon for: </label>
                <button type='button' onClick={handleToggle}>
                    {!isToggled ? 'City' : 'country'}
                </button>
                <label className='view'> Showing calculatuon for: {isToggled ? 'City' : 'country'} </label>
            </div>
            <div className='input-field'>
            
                <select id="countrySelect" value={userData.country} 
                onChange={(e) => updateData('country', e.target.value)}
                onClick={() => fetchOptions('countries') } >
                    <option value="" disabled> Destination </option>
                    {/*options && options.countries && options.countries.map((country,index) => (
                        <option key={index} value={country.country}>{country.country}</option>
                    ))*/
                    } 
                    {options && options.countries && options.countries.map((country, index) => (
                    <option key={index} value={country}>
                    {country}
                    </option>
  ))}
                </select>
                {!isToggled && userData && userData.country && userData.country.length>0 && costs  ? <label className='view' > Flight cost: ${costs.flightCost} </label> : <label> </label>}
                </div>
                {isToggled ?
                <div className='input-field'>
                 <select id="citySelect" value={userData.city} 
                onChange={(e) => updateData('city', e.target.value)}
                onClick={() => fetchCities(userData.country) } >
                    <option value="" disabled> City </option>
                    {/*options && options.countries && options.countries.map((country,index) => (
                        <option key={index} value={country.country}>{country.country}</option>
                    ))*/
                    } 
                    {options && options.cities && options.cities.map((city, index) => (
                    <option key={index} value={city}>
                    {city}
                    </option>
  ))}
                </select> 
                {userData && userData.country && userData.country.length>0 && costs  ? <label className='view' > Flight cost: ${costs.flightCost} </label> : <label> </label>}
            </div>
            : <></>}
            <div className='input-field'>
            {/*<label htmlFor="familySelect" >
                Select family size:
                </label>*/}
                <select id="familySelect" value={userData.familySize} 
                onChange={(e) => updateData("familySize", e.target.value)}
                onClick={() => fetchOptions('familySize')} >
                <option value="" disabled> Will you be bringing a spouse or dependents? </option>
                {options && options.familySize && options.familySize.map((family_type,index) => (
                        <option key={index} value={family_type}>{family_type}</option>
                    ))}
                </select>
                <label className='view'></label>
            </div>

            <div className='input-field'>
                <select id='durationSelect' value={userData.duration} 
                onChange={(e)=>{updateData('duration',e.target.value)}}
                onClick={() => fetchOptions('duration')}>
                <option value="" disabled>How long will you travel (in weeks)? </option>
                {options && options.duration && options.duration.map((week,index) => (
                        <option key={index} value={week}>{week}</option>
                    ))}
                </select>
                <label></label>
            </div>

            <div className='input-field'>
            {/*<label htmlFor="travelStyle" >
                Select travel style:
                </label>*/}
                <select id="travelStyle" value={userData.travelStyle}
                
                onChange={(e) => updateData("travelStyle", e.target.value)} 
                onClick={() => fetchOptions('travelStyle')} >
                <option value="" disabled> What is your tavel style? </option>
                {options && options.travelStyle && options.travelStyle.map((travel_style,index) => (
                        <option key={index} value={travel_style}>{travel_style}</option>
                    ))}
                </select>
                {userData && userData.country && userData.country.length>0 && costs  ? <label className='view' > Groceries cost: ${costs.grocery} </label> : <label> </label>}
            </div>
            <div className='input-field'>
            {/*<label htmlFor="accommodationSelect" >
                Select accommodation:
                </label>*/}
                <select id="accommodationSelect" value={userData.accommodation}
                 onChange={(e) => updateData("accommodation", e.target.value)}
                  >
                <option value="" disabled> Where will you stay? </option>
                {/*options && options.accommodation && Object.values(options.accommodation).map((acc,index) => (
                    <option key={index} value={acc.accommodation_size}>{acc.accommodation_size}</option>
                ))*/}
                    <option value="hotel">Hotel</option>
                    <option value="hostel">Hostel</option>
                    <option value="apartment">Apartment</option>
                </select>
                {userData && userData.country && userData.country.length>0 && costs  ? <label className='view' >Accommodation cost: ${costs.accommodation} </label> : <label> </label>}
                </div>
                <div className='input-field'>  
            {/*<label htmlFor="eatingSelect" >
                Select eating out:
            </label>*/}
                <select id="eatingSelect" value={userData.eatingOut} 
                onChange={(e) => updateData("eatingOut", e.target.value)} 
                onClick={() => fetchOptions('eatingOut')} >
                <option value="" disabled> How many times a week will you eat out for dinner? </option>
                {options && options.eatingOut && options.eatingOut.map((eating_type,index) => (
                        <option key={index} value={eating_type}>{eating_type}</option>
                    ))}
                </select>
                {userData && userData.country && userData.country.length>0 && costs  ? <label className='view' > Eating out cost: ${costs.eatingOut} </label> : <label> </label>}
            </div>

            <div className='input-field'>
            {/*<label htmlFor="activitiesSelect" >
                Select activities:
            </label>*/}
                <select id="activitiesSelect" value={userData.activities} onChange={(e) => updateData("activities", e.target.value)} >
                <option value="" disabled> Which type of activities do you enjoy? </option>
                    <option value="free">Swimming, hiking & other free activities</option>
                    <option value="paid">Surfing, cinema & other paid activities</option>
                    <option value="both">Both</option>
                </select>
                {userData && userData.country && userData.country.length>0 && costs  ? <label className='view' > Activity cost: ${costs.activities} </label> : <label> </label>}
            </div>
            <div className='input-field'>
            {/*<label htmlFor="transport" >
                Select how to get around:
            </label>*/}
                <select id="transport" value={userData.transport} onChange={(e) => updateData("transport", e.target.value)} >
                    <option value="" disabled> How will you get around? </option>
                    <option value="car">Rent a Car</option>
                    <option value="taxi">Taxi</option>
                    <option value="publicTransport">Public transport</option>
                </select>
                {userData && userData.country && userData.country.length>0 && costs && costs.transport ? <label className='view' >Transport cost: ${costs.transport} </label> : <label> </label>}
            </div>
            
            <div className='input-field'>
            <label style={{flex: '1'}}></label><label> Total costs: ${totalCost} </label>
            </div>
            <div style={{margin: '10px'}}>
            <button className="primary" style={{margin: '10px'}} type="submit" disabled={isDisable} onClick={handleSubmit}>
            Show my budget
          </button>
            <button className='reset' type='button' style={{margin: '10px'}} onClick={reset}> Reset</button>
            </div>
            </div>
            </form>
            :
            <div className="form col-lg-8 col-md-10 col-sm-12 col-xs-12 mt-10 pt-10 " >
                <h3>Your personal budget for {userData.country}</h3>
                <div className='input-field'> <label className='view' > Flight cost: </label> <label className='view fat'  >  ${costs.flightCost} </label> <a href={countryData.link_flights}> Use Skyscanner to find your ideal flight </a> </div> 
                <div className='input-field'>  <label className='view' > Groceries cost:  </label> <label className='view' >  ${costs.grocery} </label> <label style={{flex: '2'}}></label> </div> 
                <div className='input-field'> <label className='view' >Accommodation cost: </label> <label className='view' > ${costs.accommodation} </label> <a href={countryData.link_accommodation}> Remote work accommodations </a> </div> 
                <div className='input-field'> <label className='view' > Eating out cost:  </label> <label className='view' >  ${costs.eatingOut} </label> <a href={countryData.link_restaurants}> Find the perfect place for a bite </a> </div> 
                <div className='input-field'> <label className='view' > Activity cost: </label> <label className='view' >  ${costs.activities} </label> <a href={countryData.link_activities}> Affordable activities worth a look </a></div> 
                <div className='input-field'> <label className='view' >Transport cost: </label> <label className='view' > ${costs.transport} </label> {userData.transport==="car" ? <a href={countryData.link_car}> Get a great deal on your car rental </a> : <label style={{flex: '2'}}></label>}</div> 
                <div className='input-field total'> <label className='view' >Total cost: </label> <label className='view' > ${totalCost} </label> <label style={{flex:'2'}}></label> </div> 
                <button className='reset' type='button' style={{margin: '10px'}} onClick={handleSubmit}> Review my budget</button>
            </div>
}
        </div>
    );
}