import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from '../actions/adminActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

export default function ProfileScreen(){
    const adminSignin = useSelector((state) => state.adminSignin);
    const { adminInfo, loading, error } = adminSignin;
    const [ isChangePassword, setIsChangePassword] = useState(false);
    const [passwords, setPasswords] =useState(
        {
            oldPassword: '',
            newPassword: '',
            rePassword: ''
        }
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function updateData(type, newData){    // used for updating datas related to options in select
        setPasswords((passwords) =>{
          return {...passwords, [type]: newData}
        }); 
    }
    
    const submitHandler = (e) =>{
        e.preventDefault();
        if(passwords.newPassword===passwords.rePassword){
            dispatch(updatePassword(passwords));
        }
    }
    useEffect(()=> {
        if(!adminInfo){
            navigate("/signin");
        }
    },[adminInfo,navigate]);

    return(
        <div className="row">
            {adminInfo ? 
            <form className="form col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-10 pt-10 "  onSubmit={submitHandler} >
                <div className="form-setup">
                    <div className="form-input">
                    <div>
                        <h1>Hi, {adminInfo.name}. </h1>
                        {loading? <LoadingBox /> : <></>}
                        {error? <MessageBox danger="danger">{error} </MessageBox> : <></>}
                    <div className="input-login">
                        <input type="name" id="name"  disabled value={adminInfo.name} ></input>
                        <label htmlFor="name"> Name </label>
                    </div>
                    
                    <div className="input-login">
                        <input type="checkbox" id="adminRole"  disabled checked={adminInfo.role_admin} ></input>
                        <label htmlFor="adminRole"> Admin Role </label>
                    </div>
                    <div className="input-login">
                        <input type="checkbox" id="editRole" disabled checked={adminInfo.role_edit} ></input>
                        <label htmlFor="editRole"> Edit Role </label>
                    </div>
                    <div className="input-login">
                        <input type="checkbox" id="viewRole" disabled checked={adminInfo.role_view} ></input>
                        <label htmlFor="viewRole"> View Role </label>
                    </div>
                    {isChangePassword ? 
                    <>
                    <div className="input-login">
                        <input type="password" id="oldPassword" onChange={(e) => updateData('oldPassword',e.target.value)} ></input>
                        <label htmlFor="oldPassword"> Old Password </label>
                    </div>
                    <div className="input-login">
                        <input type="password" id="password" onChange={(e) => updateData('newPassword',e.target.value)} ></input>
                        <label htmlFor="password"> New Password </label>
                    </div>
                    <div className="input-login">
                        <input type="password" id="rePassword" onChange={(e) => updateData('rePassword',e.target.value)} ></input>
                        <label htmlFor="rePassword"> Password Confirmation </label>
                    </div>
                    <div className="button">
                        <button className="primary" type="submit" id="button"> Update Password </button>
                    </div>
                    </> : <div className="button">
                        <button className="primary" type="button" id="button" onClick={()=>setIsChangePassword(!isChangePassword)}> Change Password </button>
                    </div>}
                </div>
                    </div>
                </div>
            </form>
            : 
            <></>
            }
        </div>
    );
}