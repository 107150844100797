import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';


export default function AdminScreen(){
    const adminSignin = useSelector((state) => state.adminSignin);
    const { adminInfo } = adminSignin;
    const navigate = useNavigate();

    useEffect(()=> {
        if(!adminInfo){
            navigate("/signin");
        }
    },[adminInfo,navigate]);
    return(
         <div className='row'>
            <div className='button-container col-lg-6 col-md-8 col-sm-10 col-xs-12 mt-10 pt-10 ' >

                {adminInfo.role_admin ? <div className='dis-button' >
                    <Link className='button' to="/admin/createAdmin"> Create Admin </Link>
                </div> : <></>}
                {adminInfo.role_edit ?<div className='dis-button' >
                    <Link className='button' to="/admin/add"> Upload Data </Link>
                </div> : <></>}
                {adminInfo.role_view ?<div >
                    <Link className='button' to="/admin/viewTable"> View Table </Link>
                </div> : <></>}
          </div>
        </div>
    );
}